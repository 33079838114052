import Buttons from "../common/Buttons";
import SvgBorders from "../common/SvgBorders";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import { useEffect, useState } from "react";
import View from "../Component/View";

const Home = ({ img = "home.jpg" }) => {

    const [first, setfirst] = useState("abc")
    const [dataForViewing, setdataForViewing] = useState()
    
    /**
     * @description : This function is used to fetch the board result info.
     */
    const getBoardResult = async () => {
        var formdata = new FormData();
        formdata.append("board_result", "board_result");

        var requestOptions = {
        method: 'POST',
        body: formdata,
        redirect: 'follow'
        };

        fetch("https://vpsbihiya.com/portal.php?board_result=board_result", requestOptions)
        .then(response => response.text())
            .then(result => {
                if (result) {
                    setdataForViewing(JSON.parse(result))
                }
})
        .catch(error => console.log('error', error));
    }

    useEffect(() => {
        getBoardResult();
      
    }, [])
    

    const backgroundImage = require("../assets/media/images/" + img);
    const parallexImage = require("../assets/media/images/img_parallax.jpeg");
    const secondaryImage = require("../assets/media/images/home-2.jpg");
    const contact_bg = require("../assets/media/images/contact-bg.jpeg");
    const portalImage = require("../assets/media/images/portal.png");
    const staffImage = require("../assets/media/images/staff.png");
    const galleryImage = require("../assets/media/images/gallery.png");

    window.onscroll = function () { scrollDiv() };

    function scrollDiv() {
        // aboutUs

        var elementForLeft = document.getElementsByClassName('aboutUs')[0];
        if (document.documentElement.scrollTop > 750 && document.documentElement.scrollTop < 1400) {
            elementForLeft.classList.add("slideLeft");
        }
        else {
            elementForLeft.classList.remove("slideLeft");
        }
        // contactUs
        var elementForRightArr = document.getElementsByClassName('contactUs');
        for (let index = 0; index < elementForRightArr.length; index++) {
            if (document.documentElement.scrollTop > 1400 && document.documentElement.scrollTop < 2100) {
                console.log(document.documentElement.scrollTop);
                elementForRightArr[index].classList.add("slideRight");
            }
            else {
                elementForRightArr[index].classList.remove("slideRight");
            }
        }


    }



    return (
        <div className="">

            <div className="card">
                <img src={secondaryImage} className="card-img-top" alt="..." />
            </div>
            <SvgBorders type="top" color="#f1f1f1" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-4">
                        <div className="card border-light mx-auto" >
                            <div className="card-header bg-transparent border-light"><h5 className="text-blue"><i className="fa fa-bell" aria-hidden="true"></i> New session</h5></div>
                            <div className="card-body text-success">
                                <p className="card-text">{first ?? "no data"} New session will commence from 04.04.2022. And the Timing has been changed. The new timing is from 07:30 to 12:30 PM. Click to view full Notice</p>
                            </div>
                            <div className="card-footer bg-transparent border-light"><Buttons type="read-more" title="Read More" /></div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card border-light mx-auto" >
                            <div className="card-header bg-transparent border-light"><h5 className="text-blue"><i className="fa fa-bell" aria-hidden="true"></i> New session</h5></div>
                            <div className="card-body text-success">
                                <p className="card-text">New session will commence from 04.04.2022. And the Timing has been changed. The new timing is from 07:30 to 12:30 PM. Click to view full Notice</p>
                            </div>
                            <div className="card-footer bg-transparent border-light"><Buttons type="read-more" title="Read More" /></div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="card border-light mx-auto" >
                            <div className="card-header bg-transparent border-light"><h5 className="text-blue"><i className="fa fa-bell" aria-hidden="true"></i> New session</h5></div>
                            <div className="card-body text-success">
                                <p className="card-text">New session will commence from 04.04.2022. And the Timing has been changed. The new timing is from 07:30 to 12:30 PM. Click to view full Notice</p>
                            </div>
                            <div className="card-footer bg-transparent border-light"><Buttons type="read-more" title="Read More" /></div>
                        </div>
                    </div>
                </div>
                <br></br>
                <Buttons type="see-more" title="See More" />
                <br></br>
            </div>
            <div>{dataForViewing && <View data={dataForViewing}/>}</div>
            <SvgBorders type="bottom" color="#f1f1f1" />

            <div className="parallax-effect" style={{ backgroundImage: `url(${backgroundImage})` }}>
                <div className='container-fluid'>
                    <div style={{ "height": "80px" }}></div>
                    <div className="row">
                        <div className="col-md-8"></div>
                        <div className="col-md-4">
                            <div className="aboutUs card m-2 text-light" style={{ "backgroundColor": "rgba(0,0,0,0.59)" }}>
                                <div className="row g-0">
                                    <div className="col">
                                        <div className="card-body">
                                            <h5 className="card-title">About Us</h5>
                                            <p className="card-text">
                                                Established in 2005, Vivekanand Public School (Residential) is affiliated to the Central Board of Secondary Education, New Delhi.
                                                It follows the syllabus prescribed by the N.C.E.R.T. (National Council for Educational Research and Training).
                                                VPS is running on a trust named Vivekanand Jan Seva Samiti.
                                            </p>
                                            <Buttons type="btn-more" title="See More" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ "height": "80px" }}></div>
                </div>
            </div>

            <SvgBorders type="top" color="#f1f1f1" />
            <div className="container-fluid mt-2 mb-2">
                <div className="row">
                    <div className="col-md-6">
                        <div className="card border-0">
                            <div className="card-body">
                                <h5 className="card-title">Admission Policy</h5>
                                <h6 className="card-subtitle mb-2 text-muted">Card subtitle</h6>
                                <p className="card-text">
                                    VPS follows standardized admission procedures as recommended in the CBSE guidelines.
                                    Admission for montessory section to class IX is only satarted in the new session (April). There is limited vacancy of admission in each class. All applications for admission must be submitted latest by the 1st of April (new session).
                                    Previous record of student will be considered if student is coming from another school.
                                </p>
                            </div>
                        </div>
                        <Buttons type="see-more" title="See More" />
                    </div>
                    <div className="col-md-6">
                        <div className="card border-0">
                            <div className="card-body">
                                <h5 className="card-title">Residential Facaility</h5>
                                <h6 className="card-subtitle mb-2 text-muted">Card subtitle</h6>
                                <p className="card-text">
                                    Limited hostel facility is available for boys only, within the campus. Hence,
                                    it is necessary for the parents to seek admission from the hostel authorities for their wards in the hostel in the beginning of the academic year itself.
                                    Parents of the hostellers are requested to obtain a copy of the brochure and keep it with them.
                                </p>
                            </div>
                        </div>
                        <Buttons type="see-more" title="See More" />
                    </div>
                </div>
            </div>
            <SvgBorders type="bottom" color="#f1f1f1" />

            <div className="parallax-effect" style={{ backgroundImage: `url(${contact_bg})` }}>
                <div className='container-fluid'>
                    <div style={{ "height": "80px" }}></div>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="contactUs card m-2 text-light" style={{ "backgroundColor": "rgba(0,0,0,0.59)" }}>
                                <div className="row g-0">
                                    <div className="col">
                                        <div className="card-body">
                                            <h5 className="card-title">Contact Us</h5>
                                            <p className="card-text">
                                                Feel free to contact us. We'll be happy to help at any time.
                                                (Call Us: 9472853181,82,83,84)
                                                (Email at: vps.bihiya@gmail.com).
                                            </p>
                                            <Buttons type="btn-more" title="See More" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2"></div>
                        <div className="col-md-6">
                            <div className="outer-center" style={{ "minHeight": "200px" }}>
                                <div className="inner-center contactUs">
                                    <a className="btn btn-success btn-floating m-1" href="#!" role="button"><i className="fab fa-facebook-f"></i></a>
                                    <a className="btn btn-success btn-floating m-1" href="#!" role="button"><i className="fab fa-twitter"></i></a>
                                    <a className="btn btn-success btn-floating m-1" href="#!" role="button"><i className="fab fa-google"></i></a>
                                    <a className="btn btn-success btn-floating m-1" href="#!" role="button"><i className="fab fa-instagram"></i></a>
                                    <a className="btn btn-success btn-floating m-1" href="#!" role="button"><i className="fab fa-linkedin-in"></i></a>
                                    <a className="btn btn-success btn-floating m-1" href="#!" role="button"><i className="fab fa-github"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ "height": "80px" }}></div>
                </div>
            </div>

            <SvgBorders type="top" color="#f1f1f1" />
            <div className="container-fluid mt-2 mb-2">
                <div className="row">
                    <div className="col-md-6">
                        <div className="card border-0">
                            <div className="card-body">
                                <h5 className="card-title">Staff Central</h5>
                                <h6 className="card-subtitle mb-2 text-muted">Card subtitle</h6>
                                <p className="card-text">
                                    VPS has a way to lead students into right direction from begining (2005).
                                    Our faculty has been the main key to maintain this success.
                                    VPS have a enrich group of faculty, officials and staffs. Click below or more info.
                                </p>
                            </div>
                        </div>
                        <Buttons type="see-more" title="See More" />
                    </div>
                    <div className="col-md-6">
                        <div className="card border-0">
                            <div className="card-body">
                                <h5 className="card-title">Gallery</h5>
                                <h6 className="card-subtitle mb-2 text-muted">Card subtitle</h6>
                                <p className="card-text">
                                    Take a look at glimpses of Vivekanad Public School. Photos of campus and its surrounding.
                                    Annual Fuction Celebration latest photo. Checkout our facebook page for more latest photo. Click below for gallery section.
                                </p>
                            </div>
                        </div>
                        <Buttons type="see-more" title="See More" />
                    </div>
                </div>
            </div>
            <SvgBorders type="bottom" color="#f1f1f1" />


        </div>

    )
}

export default Home