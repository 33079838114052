const SvgBorders = ({type,color}) => {
  return (
    <>
    
         {(() => {
                // eslint-disable-next-line default-case
                switch(type) {
                    case 'top':
                        return <div style={{"height": "150px","marginBottom":"-80px", "overflow":"hidden"}}><svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{"height": "100%", "width": "100%"}}><path d="M-3.64,40.27 C123.17,100.47 348.39,-10.05 500.00,49.99 L500.00,0.00 L0.00,0.00 Z" style={{"stroke": "none", "fill":`${color}` }}></path></svg></div>

                    case 'bottom':
                        return <div style={{"height": "150px","marginTop":"-80px", "overflow": "hidden"}} ><svg viewBox="0 0 500 150" preserveAspectRatio="none" style={{"height": "100%", "width": "100%"}}><path d="M-3.64,98.49 C150.00,150.00 341.11,60.01 499.27,104.41 L500.00,150.00 L0.00,150.00 Z" style={{"stroke": "none", "fill":`${color}` }}></path></svg></div>

                }
            })()}
    </>
  )
}

export default SvgBorders