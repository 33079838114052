const Buttons = ({ type, title, width }) => {
    return (

        <>

            {(() => {
                // eslint-disable-next-line default-case
                switch (type) {
                    case 'read-more':
                        return <a href="https://www.google.com" className="btn-animated-1 border-0"><span>{title} </span></a>
                    case 'see-more':
                        return <div className="text-center"><a href="https://www.google.com" className="btn-animated-1 border-0"><span>{title} </span></a></div>
                    case 'btn-more':
                        return <button type='button' className="btn btn-hover btn-outline-success"><span>{title} </span></button>
                }
            })()}
        </>
    )
}

export default Buttons