import React from 'react'
import { Link } from 'react-router-dom';
import logo from "../assets/media/logo/logo-wbg.png";
const Navbar = () => {
    return (
        <nav className="navbar sticky-top navbar-expand-lg navbar-light bg-light py-3 py-lg-4">
            <div className="mx-auto d-sm-flex d-block flex-sm-nowrap">
                
                <Link className="navbar-brand" to="/">
                    <img src={logo} alt="" width="30" height="24" className="d-inline-block align-text-top" />
                    Vivekanand Public School
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarText" aria-controls="navbarText" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarText">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link to="/" className="nav-link active">Home</Link>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://www.google.com/">About Us</a>
                        </li>
                        <li className="nav-item dropdown dropdown-hover">
                            <a className="nav-link dropdown-toggle" href="https://www.google.com" id="navbarDropdownMenuLink" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Pages
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                                <li><a className="dropdown-item" href="https://www.google.com">Admission</a></li>
                                <li><a className="dropdown-item" href="https://www.google.com">Facility</a></li>
                                <li><a className="dropdown-item" href="https://www.google.com">Notice</a></li>
                                <li><a className="dropdown-item" href="https://www.google.com">Staff</a></li>
                                <li><a className="dropdown-item" href="https://www.google.com">Other Details</a></li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://www.google.com/">Gallery</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://www.google.com/">Useful Links</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="https://www.google.com/">Mandatory Public Disclosure</a>
                        </li>
                        <li className="nav-item">
                            <Link to="/contact" className="nav-link" >
                                Contact Us
                            </Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar