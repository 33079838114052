
import Navbar from "./common/Navbar";
import Home from "./public/Home";
import './assets/css/custom-style.css';
import Footer from "./common/Footer";
import {
  Routes,
  Route,
  Link
} from 'react-router-dom';
import Contact from "./public/Contact";


function App() {

  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
      <Footer />
    </>

  );
}

export default App;
