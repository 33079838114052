import React from 'react'

const View = ({ data }) => {
    if (!data || !data.length) {
        return <div>"loading..."</div>
    }
    return (
        <div>{data?.map(item => { return <div key={item.year}>{item.pass_percentage}</div> })}</div>
    )
}

export default View